import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { SignatureComponent } from './components/signature/signature.component'
import { ScrollDownButton } from './components/scrolldown/scrolldown.component';

import './App.scss';
import { HealthCareIT } from './components/healthcare-it/healthcare-it.component';
import { FusionIT } from './components/fusion-it/fusion-it.component';

// GET KEY HERE https://alvarotrigo.com/fullPage/extensions/requestKey.html

export default function App()  {
    return (
        <div className='App'>
            <ReactFullpage
                credits={{ enabled: true }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className='section first'>
                                <SignatureComponent />
                                <ScrollDownButton onClick={() => fullpageApi.moveSectionDown()} />
                            </div>
                            <div className='section'>
                                <HealthCareIT />
                                <ScrollDownButton onClick={() => fullpageApi.moveSectionDown()} />
                            </div>
                            <div className='section'>
                                <FusionIT />
                            </div>
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}  