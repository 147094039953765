import React from 'react';

import {ReactComponent as LinkedInLogo} from '../../assets/linkedin.svg';
import {ReactComponent as CSharpLogo} from '../../assets/Logo_C_sharp.svg';
import {ReactComponent as NodeJSLogo} from '../../assets/Node.js_logo.svg';
import {ReactComponent as ReactLogo} from '../../assets/React-icon.svg';

import './fusion-it.component.scss';

export const FusionIT = () => {
    return (
        <div id="fusion-it">
            <h1>Beyond Code: A Fusion of <span className='yellow'>Technology and Vision</span></h1>
            <p>
                Dive into a realm where architecture is not just about frameworks, but about envisioning the future. 
                As a software specialist, I blend the timeless principles of software engineering with the pulsating 
                rhythm of the latest tech trends. From the enigmatic world of blockchain to the intricate labyrinths 
                of high-performance computing, my skillset is a kaleidoscope that turns challenges into opportunities.
            </p>
            <p>
                Join me in a journey where each line of code is not just written, but crafted with the precision of 
                an artisan, building solutions that are not just efficient, but are also harbingers of technological evolution.
            </p>
            <p>
                <strong className='yellow'>Find me at</strong>
                <br />
                <a href='https://www.linkedin.com/in/ciosoriog/'>
                    <LinkedInLogo />
                </a>
            </p>
            <h4>Prefered technologies</h4>
            <p className='technologies'>
                <CSharpLogo />
                <NodeJSLogo />
                <ReactLogo />
            </p>
        </div>
    );
}