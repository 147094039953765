import React from 'react';

import Carlos from '../../assets/Carlos.jpg';

import './healthcare-it.component.scss';

export const HealthCareIT = () => {
    return (
        <div id="healthcare-it">
            <div className='wrapper'>
                <div>
                    <img src={Carlos} alt="Me" />
                </div>
                <div>
                    <h1>Unleashing the Power of Innovation in <span className='yellow'>Healthcare IT</span></h1>
                    <p>
                        Welcome to the digital domain of a seasoned .NET technologist, where cutting-edge healthcare information 
                        systems meet the transformative power of web development. With a rich tapestry of experience in sculpting 
                        high-availability platforms, my journey has been a symphony of creating architectures that breathe life 
                        into data. 
                    </p>
                    
                    <p>
                        Envision a world where robust, secure, and user-centric applications are not just a dream but 
                        a daily reality. Leveraging the intricate dance of machine learning algorithms, I transform complex 
                        healthcare data into actionable insights, empowering decision-makers to sculpt a healthier tomorrow.
                    </p>
                </div>
                
            </div>
        </div>
    );
}