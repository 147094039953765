import React from 'react';

import {ReactComponent as Arrow} from '../../assets/iconmonstr-arrow-down-thin.svg';

import './scrolldown.component.scss'

interface ChildProps {
    onClick: () => void
}

export const ScrollDownButton : React.FC<ChildProps> = ({ onClick }) => {
    return (
        <span className="scroll-down-button" onClick={() => onClick()}>
            <div>Scroll Down</div>
            <Arrow />
        </span>
    );
}